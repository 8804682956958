class Accordion extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    const elements = this.querySelectorAll("details");
    const handleClick = (event: MouseEvent) => {
      const currentDetails = event.target as HTMLDetailsElement;

      elements.forEach((element) => {
        element.removeAttribute("open");
      });
      currentDetails.setAttribute("open", "true");
    };
    const elementsIterator = (element: HTMLDetailsElement) => {
      element.addEventListener("click", handleClick);
    };

    elements.forEach(elementsIterator);
  }
}

customElements.define("wc-accordion", Accordion);
